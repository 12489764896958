<router-outlet></router-outlet>

<!-- [fgsColor]="'#fff'"
[bgsOpacity]="1"
[fgsPosition]="'top-center'"
[fgsSize]="0"
[fgsType]="'ball-scale-multiple'" -->
<!-- [fgsColor]="'#543333'"
[fgsPosition]="'center-center'"
[fgsType]="'ball-scale-multiple'"
[fgsSize]="50" -->
<!-- <ngx-ui-loader
  [fgsColor]="'#fff'"
  [fgsSize]="0"
  [overlayColor]="'rgba(255, 255, 255, 0.2)'"
  [hasProgressBar]="true"
  [bgsSize]="0"
></ngx-ui-loader> -->
<!-- [logoUrl]="'assets/images/mabaat.gif'"
  [logoSize]="60" -->

<ngx-ui-loader [loaderId]="'mainloader'" [fgsColor]="'#fff'" [bgsOpacity]="1" [fgsPosition]="'top-center'" [fgsSize]="0"
  [overlayColor]="'rgba(255, 255, 255, 1)'" [hasProgressBar]="false" [fgsType]="'ball-scale-multiple'"
  [logoUrl]="'assets/images/mabaat.gif'" [logoSize]="60"></ngx-ui-loader>
<notifier-container></notifier-container>

<div style="display: none">
  <asl-google-signin-button #googleRef size="large" id="signInWithGoogle"></asl-google-signin-button>
</div>
<!-- <app-server-error></app-server-error> -->

<!-- <div class="whatsup">
    <div class="whats-float">
        <a href="https://wa.me/966920023564" target="_blank">
            <i class="icon icon-Whatsapp"></i>
            
        </a>
    </div>

    </div> -->

<a href="https://wa.me/966920023564" class="float" target="_blank">
  <img class="my-float" src="../../../../../../assets/images/whatsapp.png" />

</a>