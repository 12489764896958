<div class="auth d-flex justify-content-center align-items-center">
  <ngx-ui-loader [loaderId]="'verifyLoader'"></ngx-ui-loader>

  <div class="page">
    <div class="d-flex justify-content-center mar">
      <div class="order-1 ml-auto" sty>
        <span class="iconMabaat icon icon-Mabaat---Green-Logo"></span>
      </div>
      <div class="order-2 ml-auto" style="cursor: pointer;" (click)="bsModalRef.hide()">
        <span class="icon-Icon-ionic-ios-close" style="color: #543333;"></span>
      </div>
    </div>
    <div>
      <div class="head">
        {{"enter_your_code"|translate}}
      </div>
      <div style="direction: ltr;">
        <!-- <ngx-pincode 
            borderColor="#e6eaef" 
            [hideChrs]="false" 
            type="number" 
            [size]="4" 
            (completed)="pincodeCompleted($event)">
        </ngx-pincode> -->
        <ngx-otp-input [config]="otpInputConfig" (fill)="handleFillEvent($event)"></ngx-otp-input>
      </div>
      <!-- <div class="d-flex justify-content-center" style="direction: ltr;">
        <div class="myRectangle mr-2">
          <input #ref1 (keyup)="ref2.focus()" maxLength="1" size="1" placeholder="-" style="width: 100%; border: none;" type="number">
        </div>
        <div class="myRectangle mr-2">
          <input #ref2 (keyup)="ref3.focus()" maxLength="1" size="1" placeholder="-" style="width: 100%; border: none;" type="number">
        </div>
        <div class="myRectangle mr-2">
          <input #ref3 (keyup)="ref4.focus()" maxLength="1" size="1" placeholder="-" style="width: 100%; border: none;" type="number">
        </div>
        <div class="myRectangle mr-2">
          <input #ref4  maxLength="1" size="1" placeholder="-" style="width: 100%; border: none;" type="number">
        </div>
      </div> -->
      <div class="d-flex">
        <div class="order-1 mr-3" style="flex-basis: 100%;">
          <button class="Preview" (click)="sendCodeAgin()">
            <span>{{"resend_code"|translate}}</span>
          </button>
        </div>
        <div class="order-1 ml-auto" style="flex-basis: 100%;">
          <button class="Preview" style="background-color: #346767;" (click)="verifiedCode()">
            <span>{{"submit"|translate}}</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>