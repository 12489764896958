import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { AuthService } from './modules/shared/auth/auth.service';
// import { AuthGuard } from './modules/shared/auth/auth.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { ApiService } from './modules/core/api.service';
import {
  NgxUiLoaderConfig,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
} from 'ngx-ui-loader';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { AddRatingModalModule } from './modules/base/allModals/add-rating-modal/add-rating-modal.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { AuthenticationModalModuleModule } from './modules/base/allModals/authentication/authentication-modal-module.module';
import { Error404Module } from './modules/base/children-modules/error-404/error-404.module';

import { HttpClientJsonpModule } from '@angular/common/http';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 10000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 10,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
// const ngxUiLoaderConfig: NgxUiLoaderConfig = {
//   overlayColor: 'rgba(255, 255, 255, 0)',
//   hasProgressBar: true,

//   // bgsPosition: POSITION.bottomCenter,
//   // bgsSize: 40,
//   // bgsType: SPINNER.rectangleBounce, // background spinner type
//   // fgsType: SPINNER.chasingDots, // foreground spinner type
//   // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
//   // pbThickness: 5, // progress bar thickness
// };
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  overlayColor: 'rgba(255, 255, 255, 0.8)',
  fgsColor: '#543333',
  hasProgressBar: false,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 40,
  // bgsType: SPINNER.rectangleBounce, // background spinner type
  // fgsType: SPINNER.chasingDots, // foreground spinner type
  // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 5, // progress bar thickness
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AddRatingModalModule,
    AuthenticationModalModuleModule,
    ModalModule.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    TranslateModule.forRoot({
      //defaultLanguage: localStorage.getItem("lang"),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule.forRoot({
      exclude: ['/properties'],
    }),
    Error404Module,
    HttpClientJsonpModule,
    SocialLoginModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              //'413635543755-veju9apbs6i9195gp8vvk23lgflm9c3i.apps.googleusercontent.com'
              '22573655509-v0ol7vt8qv3v8tosprnn0l8u7ji02gsj.apps.googleusercontent.com',
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('5364359343634421'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    // AuthService,
    // AuthGuard,
    // ApiService,
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
