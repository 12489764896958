<div class="auth">
  <ngx-ui-loader [loaderId]="loaderID"></ngx-ui-loader>
  <div class="header">
    <div class="head">
      <div class="d-inline-block">{{"welcome_to_mabaat"|translate}}</div>
      <div (click)="bsModalRef.hide()" class="closeBtn"><span class="icon-Icon-ionic-ios-close"></span></div>
    </div>
    <div class="subHead">{{"sign_in_up"|translate}}</div>
  </div>
  <div class="page text-left">
    <!-- <div class="d-flex justify-content-center mar">
      <div class="order-1 ml-auto">
        <span class="iconMabaat icon icon-Mabaat---Green-Logo"></span>
      </div>
      <div class="order-2 ml-auto" style="cursor: pointer;" (click)="bsModalRef.hide()">
        <span class="icon-Icon-ionic-ios-close" style="color: #543333;"></span>
      </div>
    </div>
    <div class="d-flex justify-content-center mar">
      <div class="order-1 text">
        {{"sign_in_up"|translate}}
      </div>
    </div>
    <div class="d-flex justify-content-center mar">
      <div class="order-1 smallCard mx-3" (click)="signInWithFB()">
        <span class="icon-Icon-awesome-facebook-square" style="color: #4267b2; font-size: 20px;"></span>
      </div>
      <div class="order-2 smallCard mx-3" (click)="signInWithGoogle()" >
        <span class="icon-google" style="color: #f14336; font-size: 20px;"></span>
      </div>
    </div> -->
    <!-- <div class="separator mar">OR</div> -->

    <form class="control_formField w-100" [formGroup]="loginForm">
      <div class="inputLabel">{{"email"|translate}}</div>
      <mat-form-field appearance="outline">
        <input formControlName="email" type="email" matInput placeholder="{{'enter_your_email'|translate}}">
        <span class="icon_insideInput_Prefix icon-email2" matPrefix></span>
        <!-- <mat-icon matPrefix>email</mat-icon> -->
        <mat-error *ngIf="loginFormRef?.email?.errors?.email && !loginFormRef?.email?.errors?.required">
          {{'please_enter_valid_email_address'|translate}}
        </mat-error>
        <mat-error *ngIf="loginFormRef?.email?.errors?.required">
          {{'email_is_require'|translate}}
        </mat-error>
      </mat-form-field>
      <div class="inputLabel">{{'password'|translate}}</div>
      <mat-form-field appearance="outline">
        <input formControlName="password" type="password" matInput placeholder="{{'enter_your_password'|translate}}">
        <span class="icon_insideInput_Prefix icon-password" matPrefix></span>
        <mat-error *ngIf="loginFormRef?.password?.errors?.required">
          {{'password_is_require' |translate}}
        </mat-error>
      </mat-form-field>
    </form>

    <div class="d-flex">
      <div class="order-1 forget_password mt-auto ml-auto">
        <span (click)="openModalForgetPassword()" class="create">{{'forget_password' | translate}}</span>
      </div>
    </div>

    <button (click)="login()" class="login">{{"login"|translate}}</button>

    <div class="d-flex justify-content-center">
      <div class="haveAccount">{{"you_are_new_user"|translate}} <span
          (click)="openModalSignin()">{{"create_an_account"|translate}}</span></div>
    </div>

    <app-social-login [loaderID]="loaderID"></app-social-login>

  </div>
</div>